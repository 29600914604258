<template>
  <div class="form__field input-wrapper" :class="{ 'form__field--error': error, 'form__field--columns': isColumned }">
    <label v-if="label && !['checkbox', 'radio'].includes(type)" class="form__field__label" :class="{ 'visually-hidden': hideLabel }" :for="uid">
      {{ label }} <small v-if="notRequired">необязательно</small>
    </label>
    <div class="form__field__inner">
      <div class="form__field__input-wrap">
        <template v-if="type === 'custom'">
          <slot :uid="uid"></slot>
        </template>
        <template v-else-if="type === 'multiselect' || (type === 'select' && search)">
          <Multiselect ref="select"
                       v-model="boundValue"
                       :mode="type === 'select' ? 'single' : 'multiple'"
                       :placeholder="placeholder"
                       :searchable="!!search"
                       :valueProp="search?.prop ?? 'id'"
                       :trackBy="search?.track ?? 'name'"
                       :label="search?.label ?? 'name'"
                       :options="options"
                       noResultsText="Ничего не найдено"
                       noOptionsText="Нет данных"
                       :id="uid"
                       v-bind="$attrs">
            <template #caret>
              <span class="form__field__icon icon-chevron-down"></span>
            </template>
          </Multiselect>
        </template>
        <template v-else-if="type === 'select'">
          <select class="form__field__input" v-model="boundValue" :id="uid" :disabled="disabled">
            <option v-if="placeholder" :value="undefined" disabled selected>{{ placeholder }}</option>
            <slot></slot>
          </select>
          <span class="form__field__icon icon-chevron-down"></span>
        </template>
        <template v-else-if="type === 'segmented'">
          <div class="form__field__segmented-wrap">
            <label v-for="item of options" :key="item.id" class="form__field__segmented">
              <input class="visually-hidden" type="radio" :name="uid" v-model="boundValue" :value="item.id" :disabled="disabled" />
              <span class="form__field__segmented__card">
                {{ item.name }}
              </span>
            </label>
          </div>
        </template>
        <template v-else-if="type === 'textarea'">
          <textarea class="form__field__input" :id="uid" :placeholder="placeholder" v-model="boundValue" :maxlength="maxlength" :disabled="disabled"></textarea>
          <div class="dnc-realized-projects__counter" v-if="maxlength">{{ boundValue?.length ?? 0 }}/{{ maxlength }}</div>
        </template>
        <template v-else-if="type === 'checkbox'">
          <label class="checkbox">
            <input type="checkbox" :value="value" v-model="boundValue" :disabled="disabled" />
            <span class="checkbox__checkmark"></span>
            <span>{{ label }}</span>
            <span class="checkbox-desc"><slot name="label"></slot></span>
          </label>
        </template>
        <template v-else-if="type === 'radio'">
          <label class="checkbox">
            <input type="radio" :name="name" :value="value" v-model="boundValue" :disabled="disabled" />
            <span class="checkbox__checkmark"></span>
            <span>{{ label }}</span>
          </label>
        </template>
        <template v-else-if="type === 'link'">
          <input class="form__field__input" type="text" :id="uid" v-model="boundValue" :placeholder="placeholder" :disabled="disabled">
          <span class="form__field__icon icon-link"></span>
        </template>
        <input v-else class="form__field__input input" :type="type" :id="uid" v-model="boundValue" :placeholder="placeholder" :disabled="disabled">
        <slot name="icon"></slot>
      </div>
      <small v-if="error" class="form__field__caption form__field__caption--error">{{ error }}</small>
      <small v-if="$slots.hint" class="form__field__caption">
        <slot name="hint"></slot>
      </small>
      <slot name="afterInput"></slot>
    </div>
  </div>
</template>

<script setup>
import Multiselect from '@vueform/multiselect'

// TODO: link input
// TODO: password input
// TODO: radio and checkboxes
// TODO: replace the textarea character limit hint class with a generic one
// TODO: get a proper clear icon for the multiselect and replace the stock one

const props = defineProps({
  label: String,
  hideLabel: Boolean,
  modelValue: [String, Number, Boolean, Array],
  name: String,
  value: {
    type: [String, Number, Boolean],
    default: true
  },
  error: String,
  type: String,
  columns: Boolean,
  disabled: Boolean,
  placeholder: String,
  onMounted: Function,
  notRequired: Boolean,
  maxlength: Number,
  search: [Boolean, Object],
  options: Array
})
const emit = defineEmits(['update:modelValue'])

const select = ref(null)
const uid = 'input' + getCurrentInstance().uid

const columns = false
const isColumned = computed(() => props.columns || columns.value)

const boundValue = computed({
  get: () => props.modelValue,
  set: val => emit('update:modelValue', val)
})

defineExpose({ select })

onMounted(() => {
  if (props.onMounted) {
    props.onMounted(uid)
  }
})
</script>

<style>
.checkbox-desc a {
  color: var(--color-primary);
}
.checkbox-desc {
  text-transform: none;
  opacity: .7;
}
</style>
